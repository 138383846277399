<template>
    <div class="AppListItem">
        <div class="col-content">
            <div class="appTitle">
                <div class="appLogo">
                    <img :src="app.iconUrl" v-bind:alt="pic">
                </div>
                <div class="appName">{{ app.name }}</div>
            </div>
            <div class="appDesc">{{ app.description }}</div>
            <button :data-testid="app.key"
                    class="button" type="button" @click="LoadApp()">
                {{ $t('launch') }}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
    import AppRedirectService from '@/services/AppRedirectService';
    import * as Vue from 'vue';

    export default Vue.defineComponent({
        name: 'AppListItem',
        props: {
            app: Object
        },
        methods: {
            LoadApp() {
                const openInNewTab = this?.$route?.query?.sameWindow != "true";
                AppRedirectService.Go(this.app?.appRedirectUrl ?? '', this.app?.key ?? '', openInNewTab);
            }
        }
    });
</script>

<style lang="scss">
.AppListItem {
    display: flex;
    margin: 0 0 40px 0;
    align-items: center;
    padding: 0;

    .col-content {
        background: #fff;
        border-radius: $element_border_radius;
        padding: $standard_space / 2;
        border: 2px solid #fff;
        height: 100%;
        padding-bottom: 2.0rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
        min-width: 200px;
	    min-height: 360px;

        a {
            font-size: 0.9rem;
            bottom: 5px;
            right: $standard_space / 2;
            color: $primary_color;
            font-weight: bold;
        }

        button {
            min-width: 125px;
            max-width: 125px;
            max-height: 40px;
            min-height: 40px;
            padding: 0px;
            margin-top: auto;
        }

        .appLogo {
            padding-top: $standard_space * 2;
            padding-bottom: $standard_space;
            top: 15px;
            left: $standard_space / 2;
            text-align: center;

            img {
                width: 84px;
                height: 84px;
                border-radius: 6px;
            }
        }
    }

    .appName {
        font-weight: bold;
        margin-bottom: 5px;
        text-align: center;
        font-size: 1.25rem;
    }

    .appDesc {
        font-size: 0.9rem;
        text-align: center;
        padding-bottom: $standard_space;
        margin-left: $standard_space / 2;
        margin-right: $standard_space / 2;
    }

    hr {
        margin-left: $standard_space / 2;
        margin-right: $standard_space / 2;
    }

    .header-left {
        padding-left: $standard_space / 2;
        margin-bottom: 0px;
    }
}

@media all and (max-width: 700px) {
    .AppListItem, .col-content {
        width: auto;
        min-width: unset;
        max-width: unset;
    }
}

@media all and (max-width: 575px) {
	.AppListItem {

		.col-content {
			min-height: unset; // 210px;
			padding: 0;
			padding-bottom: 0;
			align-items: flex-start;

			.appTitle {
				display: flex;
			}

			button {
				align-self: center;
				width: 80%;
				min-width: unset;
				max-width: unset;
				margin-bottom: $standard_space / 2;
			}

			.appLogo {
				padding-top: $standard_space / 2;
				padding-bottom: $standard_space / 2;
				margin-left: $standard_space / 2;

				img {
					width: 48px;
					height: 48px;
				}
			}
		}

		.appName {
			text-align: left;
			font-size: 1.1rem;
			padding-top: $standard_space;
			padding-left: $standard_space / 2;
		}

		.appDesc {
			text-align: left;
			padding-bottom: $standard_space;
			min-height: unset; //80px;
		}
	}
}
</style>